import { useEffect, useState } from "react";
import loading from '../../theme/img/loading-white.svg';

const Loader = (props) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  return (
    <div
      style={{
        visibility: show ? "visible" : "hidden",
        opacity: show ? "1" : "0"
      }}
      className="loader-overlay"
    >
      <div className="loader-main">
        <img src={loading} className="loader-img" />
      </div>
    </div>
  );
};

export default Loader;