export const setUserActivities = (data) => {
    return {
        type: "SET_USER_ACTIVITIES",
        payload: data,
    };
};

export const setUser = (data) => {
    return {
        type: "SET_USER",
        payload: data,
    };
};

export const setTitle = (data) => {
    return {
        type: "SET_TITLE",
        payload: data,
    };
};

export const setLocation = (data) => {
    return {
        type: "SET_LOCATION",
        payload: data,
    };
};

export const setMessage = (data) => {
    return {
        type: "SET_MESSAGE",
        payload: data,
    };
};

export const setElevation = (data) => {
    return {
        type: "SET_ELEVATION",
        payload: data,
    };
};

export const setSmallText = (data) => {
    return {
        type: "SET_SMALL_TEXT",
        payload: data,
    };
};

export const setGeoJson = (data) => {
    return {
        type: "SET_GEO_JSON",
        payload: data,
    };
};

export const setStyle = (data) => {
    return {
        type: "SET_STYLE",
        payload: data,
    };
};

export const setShowElevation = (data) => {
    return {
        type: "SET_SHOW_ELEVATION",
        payload: data,
    };
};

export const setShowStartStop = (data) => {
    return {
        type: "SET_SHOW_START_STOP",
        payload: data,
    };
};

export const setCanvasSize = (data) => {
    return {
        type: "SET_CANVAS_SIZE",
        payload: data,
    };
};

export const setStravaJson = (data) => {
    return {
        type: "SET_STRAVA_JSON",
        payload: data,
    };
};