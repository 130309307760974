import React  from 'react';
import { useHistory } from "react-router-dom";

import backArrow from '../../theme/img/back-arrow.svg';

const Header = props => {
    const history = useHistory();
    return (
      <div className="top-link">
        {/* {history?.location?.pathname !== '/' && */}
          {/* <a onClick={() => history.push('/products/design-your-own-trail-map')}>
              <img src={backArrow} alt="Back to upload" />
          </a>  */}
        {/* } */}
      </div> 
    );
  }
  
  export default Header;
  