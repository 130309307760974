import { combineReducers } from "redux";

const setUserReducer = (tokens = null, action) => {
    switch (action.type) {
        case "SET_USER":
            return action.payload;
        default:
            return tokens;
    }
};

const setUserActivitiesReducer = (user = null, action) => {
    if (action.type === "SET_USER_ACTIVITIES") {
        return action.payload;
    }
    return user;
};

const setLocationReducer = (state = null, action) => {
    if (action.type === "SET_LOCATION") {
        return action.payload;
    }
    return state;
};

const setTitleReducer = (state = null, action) => {
    if (action.type === "SET_TITLE") {
        return action.payload;
    }
    return state;
};

const setMessageReducer = (state = null, action) => {
    if (action.type === "SET_MESSAGE") {
        return action.payload;
    }
    return state;
};

const setElevationReducer = (state = null, action) => {
    if (action.type === "SET_ELEVATION") {
        return action.payload;
    }
    return state;
};

const setSmallReducer = (state = null, action) => {
    if (action.type === "SET_SMALL_TEXT") {
        return action.payload;
    }
    return state;
};

const setGeoJson = (state = null, action) => {
    if (action.type === "SET_GEO_JSON") {
        return action.payload;
    }
    return state;
};

const setStyle = (state = null, action) => {
    if (action.type === "SET_STYLE") {
        return action.payload;
    }
    return state;
};

const setShowElevation = (state = null, action) => {
    if (action.type === "SET_SHOW_ELEVATION") {
        return action.payload;
    }
    return state;
};

const setShowStartStop = (state = null, action) => {
    if (action.type === "SET_SHOW_START_STOP") {
        return action.payload;
    }
    return state;
};

const setCanvasSize = (state = null, action) => {
    if (action.type === "SET_CANVAS_SIZE") {
        return action.payload;
    }
    return state;
};

const setStravaJson = (state = null, action) => {
    if (action.type === "SET_STRAVA_JSON") {
        return action.payload;
    }
    return state;
};

export default combineReducers({
    returnTokens: setUserReducer,
    user: setUserActivitiesReducer,
    location: setLocationReducer,
    title: setTitleReducer,
    message: setMessageReducer,
    elevation: setElevationReducer,
    small: setSmallReducer,
    geoJson: setGeoJson,
    style: setStyle,
    showElevation: setShowElevation,
    showStartStop: setShowStartStop,
    canvasSize: setCanvasSize,
    stravaJson: setStravaJson
});