import React  from 'react'

const NotFound = () => {
    return (
      <div>
        Page not found
      </div>
    );
  }
  
  export default NotFound;
  