import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

 
// Initialize Firebase
const app = initializeApp ({
    apiKey: "AIzaSyAsX8cHHMQk1CJaVlLvOg5YISPRQG3PgkI",
    authDomain: "app-trailmaps-byo.firebaseapp.com",
    projectId: "app-trailmaps-byo",
    storageBucket: "app-trailmaps-byo.appspot.com",
    messagingSenderId: "477162808783",
    appId: "1:477162808783:web:43fa69f791fde6c66cdeba"
});
 
// Firebase storage reference
const storage = getStorage(app);
export default storage;