import React  from 'react'

const Error = () => {
    return (
      <div>
        
      </div>
    );
  }
  
  export default Error;
  