import React, {useState, useRef, useEffect}  from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from "react-redux";
import toGeoJson from '@mapbox/togeojson'
import { DOMParser } from 'xmldom';
import * as turf from "@turf/turf";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { REACT_APP_CLIENT_ID } from '../utils/functions';
import Loader from '../components/Loader';

// Theme Images
import strava from '../theme/img/strava.svg';
import arrowRight from '../theme/img/angle-right.svg';
import angleRightBlack from '../theme/img/angle-black.svg';
import trustPilot from '../theme/img/trustpilot.svg';

//State
import { setGeoJson } from "../actions";

const Home = (props) => {
  const history = useHistory();
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isStrava, setIsStrava] = useState(false);
  const [visibility, setVisibility] = useState(false);
  const [activities, setActivities] = useState({})
  const [selectedSource, setSelectedSource] = useState(false);
  const [loaderVisibility, setLoaderVisibility] = useState(false);
  const inputFile = useRef(null);
  const [hasUploaded, setHasUploaded] = useState(false);
  const [gpxArray, setGpxArray] = useState([]);

  const redirectUrl = "https://dyo.trailmaps.com/redirect";
  // const redirectUrl = "https://trailmaps.com/products/dyo";
  // const redirectUrl = "http://localhost:3000/redirect";
  const scope = "activity:read_all"

  const handleStrava = (e) => {
    e.preventDefault();
    window.location = `http://www.strava.com/oauth/authorize?client_id=${REACT_APP_CLIENT_ID}&response_type=code&redirect_uri=${redirectUrl}/exchange_token&approval_prompt=force&scope=${scope}`;
  }


  const handleNextStep = (e) => {
    e.preventDefault();
    if(isValid) {
      props.setGeoJson(gpxArray)
      history.push('/products/design-your-own-trail-map/design')
    }
  }

  const handleGpx = (e) => {
    e.preventDefault();
    inputFile.current.click();
  }

  const handleGpxUpload = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // setLoaderVisibility(true);
    const file = e.target.files[0];

    const reader = new FileReader();

    reader.readAsText(file , "UTF-8");

    reader.onload = function (evt) {
        var data = evt.target.result;
        const source = new DOMParser().parseFromString(data)
        const geoJson = toGeoJson.gpx(source)
        setIsValid(true);
        setHasUploaded(true);

        let items = geoJson.features[0].geometry.coordinates;

        let i = Math.floor(items.length / 0.5);

        while (i--) {
          items.splice((i + 1) * 2 - 1, 1);
        }

        geoJson.features[0].geometry.coordinates = [];
        geoJson.features[0].geometry.coordinates = items

        // setLoaderVisibility(false);
        setGpxArray(gpxCurrentList => [...gpxCurrentList, geoJson]);
     }
  } 

  const handleOnDragEnd = (result) => {
    const items = Array.from(gpxArray);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setGpxArray(items);
  }

  const handleRemoveGpx = (e) => {
    const button = e.currentTarget.parentNode.parentNode;
    const position = [...document.querySelector('#current-list').children].indexOf(button);
    setGpxArray(gpxArray.splice(position, 1))
  }


  useEffect(() => {
    if(gpxArray.length > 0) {
      props.setGeoJson(gpxArray)
    }
  }, [gpxArray])

  return (
      <>
      <Loader
          show={loaderVisibility}>
      </Loader>
      <div className="step-title">
          <span>STEP 1</span>
          <div className="flex space-between ">
              <h2>Upload your route</h2>
              <a href="https://trailmaps.com/pages/help-with-the-design-your-own-map-builder" target="_blank">Help</a>
          </div>
      </div>
        {!hasUploaded &&
        <div className="connect-upload">
          {/* <a onClick={handleStrava}>
              <div className="connect flex space-between acenter">
                  <div className="c-left acenter flex">
                      <span>Connect with</span>
                      <img src={strava} alt="strava" />
                  </div>
                  <div className="c-right">
                      <img src={arrowRight} alt="right" />
                  </div>
                  
              </div>
          </a> */}
          <a onClick={handleGpx}>
              <div className="upload flex space-between acenter">
                  <div className="c-left">
                      <span>Upload GPX file(s)</span>
                  </div>
                  <div className="c-right">
                      <img src={angleRightBlack} alt="right" />
                  </div>
              </div>
          </a>
        </div>
        }

        {hasUploaded &&
          <>
            <div className="text-content">
              <h3>Are they in the right order?</h3>
              <p>You can re-order by dragging and dropping the rides, this will ensure the elevation profile is accurate.</p>
            </div>
            {gpxArray && gpxArray.length > 0 && (
              <>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="maps-list">
                {(provided) => (
                <div className="maps-list map-order-list ui-sortable" id="current-list" {...provided.droppableProps} ref={provided.innerRef}>
                  {gpxArray.map((item, index) => (
                   <Draggable key={`${item.features[0].properties.name}${index}`} draggableId={`${item.features[0].properties.name}${index}`} index={index} >
                   {(provided) => ( 
                      <div className="map-single flex space-between ui-sortable-handle" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <div className="map-left">
                          <h3>{item?.features[0]?.properties?.name}</h3>
                          <p>{item?.features[0]?.properties?.type}<br/>
                          {`${turf.length(item, {units: 'kilometers'}).toFixed(2)}km`}</p>
                            <button className="remove added added-list" onClick={handleRemoveGpx}>
                                        <svg width="14px" height="3px" viewBox="0 0 14 3" version="1.1" >
                                            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="square">
                                                <g id="Upload---02" transform="translate(-73.000000, -451.000000)" stroke="#D93708" stroke-width="2">
                                                    <g id="Group-Copy" transform="translate(37.000000, 300.000000)">
                                                        <g id="Group-4-Copy" transform="translate(43.000000, 152.500000) rotate(-90.000000) translate(-43.000000, -152.500000) translate(42.000000, 147.000000)">
                                                            <line x1="1" y1="0" x2="1" y2="11" id="Line-Copy-2"></line>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                <span>Remove</span>
                            </button>
                        </div>
                        {provided.placeholder}
                      </div>
                  )}
                  </Draggable>
                  ))}
                </div>
                 )}
                 </Droppable>
                </DragDropContext>
                
                <div className="add-more">
                    <p>Need to add more?</p>
                    <p>
                        {/* <a onClick={handleStrava}>Add from Strava</a>
                        <span> or </span> */}
                        <a onClick={handleGpx}>Upload GPX</a>
                    </p>
                </div>
            </>
            )}
          </>
        }

        <div className="bottom-links">
        <input type='file' id='file' accept="gpx" ref={inputFile} style={{display: 'none'}} onChange={handleGpxUpload}/>
            <div className="next-step">
                <a href="#">
                    <div className={`connect flex space-between acenter ${!isValid && `opacity-5`}`} onClick={handleNextStep}>
                        <div className="c-left">
                            <span>Next Step - Design</span>
                        </div>
                        <div className="c-right">
                            <img src={arrowRight} alt="right" />
                        </div>
                    </div>
                </a>
            </div>
            <div className="trust-pilot flex space-between acenter">
                <div className="tp-left">
                    <span>
                        <strong>Excellent</strong> | Trustscore 4.9 | <a href="#">152 reviews</a>
                    </span>
                </div>
                <div className="tp-left right">
                    <img src={trustPilot} alt="trust-pilot" />
                </div>
            </div>
        </div>
                
      </>
    );
  }
  
  const mapStateToProps = (state) => {
    return { 
        geoJson: state.geoJson,
    };
  };

  export default connect(mapStateToProps, {
    setGeoJson,
  })(Home);
  