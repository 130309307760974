import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import './theme/scss/app.scss';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

// Store
import { Provider } from "react-redux";
import reducers from "./reducers";
import { createStore } from "redux";
import configureStore from '@reduxjs/toolkit'

// Components
import Header from './components/Header';
import Map from './components/Map';

// Routes
import Error from './routes/error';
import Home from './routes/home';
import Design from './routes/design';
import NotFound from './routes/notFound';
import StravaRedirect from './routes/stravaRedirect';
import StravaList from './routes/stravaList';


// Utils
import GA from './utils/googleAnalytics';


class App extends Component {
  render () {
    return (
      <Provider
        store={createStore(
            reducers,
        )}
    >
        <BrowserRouter>
          { GA.init() && <GA.RouteTracker /> }
          <>
            <div className="main-area">
              <div className="wrapper">
                <div className="sidebar">
                  <>
                    <Header />
                    <Switch>
                      <Route exact path='/products/design-your-own-trail-map' component={Home} />
                      <Route path='/products/design-your-own-trail-map/design' component={Design} />
                      <Route path='/error' component={Error} />
                      <Route path="/products/design-your-own-trail-map/redirect" component={StravaRedirect} />
                      <Route path="/products/design-your-own-trail-map/list" component={StravaList} />
                      <Route component={NotFound} />
                    </Switch>
                  </>
                </div>
                <Map />
              </div>
            </div>
          </>
        </BrowserRouter>
      </Provider>
    )
  }
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
