import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import GpxList from "../components/GpxList";

// Theme Images
import arrowRight from '../theme/img/angle-right.svg';
import trustPilot from '../theme/img/trustpilot.svg';
import shadow from '../theme/img/shadow.svg';

// State
import { setStravaJson } from "../actions";

const polyline = require('@mapbox/polyline');

const StravaList = ({ user, returnTokens, setStravaJson }) => {
    const history = useHistory();
    const callActivities = `https://www.strava.com/api/v3/athlete/activities?access_token=`;

    const [activities, setActivities] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    
    const getActivities = (access) => {
        fetch(callActivities + access)
        .then(res => res.json())
        .then(data =>  setActivities(data), setIsLoading(prev => !prev))
        .catch(e => console.log(e))
    }

    useEffect(() => {
        getActivities(returnTokens?.access_token)
      }, [])


    const handleNextStep = (e) => {
        e.preventDefault();
        // if(isValid) {
          history.push('/products/design-your-own-trail-map/design')
        // }
    }

    const handleAdd = (map) => {
        let coordinates = [];
        const coords = decodePolyline(map.map.summary_polyline);

        for (let i = 0; i < coords.length; i++) {
            coords[i] = [
                coords[i][1],
                coords[i][0]
            ];
          }
          
        coordinates = coords;   

        const activity = {
            coords: coordinates
        }

        setStravaJson(activity);
    }

    const decodePolyline = (encodedString) => { 
        if (!encodedString) return []; 
        const decoded = polyline.decode(encodedString); 
        
        return decoded; 
    };

    return (
        <>
            <div className="step-title">
                <span>STEP 1</span>
                <div className="flex space-between ">
                    <h2>Upload your route</h2>
                    <a href="https://trailmaps.com/pages/help-with-the-design-your-own-map-builder" target="_blank">Help</a>
                </div>
            </div>
            <GpxList 
                handleAdd={handleAdd}
                content={activities}
            />
            <div className="bottom-links">
            <div className="shadow">
                <img src={shadow} alt="shadow" />
            </div>
            <div className="next-step">
                <a href="#">
                    <div className={`connect flex space-between acenter ${/*!isValid && `opacity-5`*/''}`} onClick={handleNextStep}>
                        <div className="c-left">
                            <span>Next Step - Design</span>
                        </div>
                        <div className="c-right">
                            <img src={arrowRight} alt="right" />
                        </div>
                    </div>
                </a>
            </div>
            <div className="trust-pilot flex space-between acenter">
                <div className="tp-left">
                    <span>
                        <strong>Excellent</strong> | Trustscore 4.9 | <a href="#">152 reviews</a>
                    </span>
                </div>
                <div className="tp-left right">
                    <img src={trustPilot} alt="trust-pilot" />
                </div>
            </div>
        </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
        returnTokens: state.returnTokens,
        strava: state.strava,
        stravaJson: state.stravaJson,
    };
};

export default connect(mapStateToProps, {
    setStravaJson,
})(StravaList);