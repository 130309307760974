import React, { useState, useEffect }  from 'react';

import mapImage from '../../theme/img/map.svg';

const GpxList = props => {
    const { content, handleAdd, handleRemove } = props; 
    const [ monthContent, setMonthsContent ] = useState([]);
    let useableDateContent = []
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    if(content.length > 0) {
        useableDateContent = content.map((item) => {
        const date = new Date(item.start_date);
        const month = date.getMonth();
        const year = date.getFullYear();

        return {
          ...item,
          start_date: `${monthNames[month]} ${year}`
        }
      })
    }

    const groupBy = (xs, key) => {
      return xs.reduce((rv, x) => {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };

    const byDate = groupBy(useableDateContent, 'start_date');
    const byDateKeys = Object.keys(byDate)


    const handleOnChange = (e) => {
      setMonthsContent(byDate[e.target.value]);
    }

    const secondsToHms = (d) => {
      d = Number(d);
      var h = Math.floor(d / 3600);
      var m = Math.floor(d % 3600 / 60);
      var s = Math.floor(d % 3600 % 60);
      return `${h}:${m}:${s}`; 
  }




    return (
      <div className="text-content">
        <div className="select-dropdown">
            {<select name="select" id="select" onChange={handleOnChange}>
              {byDateKeys.map((month, i) => {
                return <option value={month}>{month}</option>
              })}
            </select>}

            <div className="maps-list">
              {monthContent.length > 0 &&
                monthContent.map(map => {
                  const {name, start_date_local, moving_time, distance, total_elevation_gain} = map;

                  const date = new Date(start_date_local);
                  const day = date.getDay()
                  const month = date.getMonth();
                  const year = date.getFullYear();

                  const distanceAsValue = distance /1000

                  return (
                    <div className="map-single flex space-between">
                      <div className="map-left">
                          <h3>{name}</h3>
                          <p>{day}.{month}.{year} <br />
                          {distanceAsValue.toFixed(2)}km - {total_elevation_gain}m - {secondsToHms(moving_time)}</p>
                          <button className="add" onClick={() => handleAdd(map)}>
                              <>
                              <svg width="14px" height="13px" viewBox="0 0 14 13" version="1.1">
                                  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="square">
                                      <g id="Upload---02" transform="translate(-73.000000, -446.000000)" stroke="#D93708" stroke-width="2">
                                          <g id="Group-Copy" transform="translate(37.000000, 359.000000)">
                                              <g id="Group-4-Copy" transform="translate(43.000000, 93.500000) rotate(-90.000000) translate(-43.000000, -93.500000) translate(37.000000, 88.000000)">
                                                  <line x1="6" y1="0" x2="6" y2="11" id="Line-Copy-2"></line>
                                                  <line x1="11.5" y1="5.5" x2="0.5" y2="5.5" id="Line-Copy-2"></line>
                                              </g>
                                          </g>
                                      </g>
                                  </g>
                              </svg>
                              <span>Add</span>
                              </>
                          </button>
                          <button className="remove" onClick={handleRemove}>
                              <>
                              <svg width="14px" height="3px" viewBox="0 0 14 3" version="1.1">
                                  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="square">
                                      <g id="Upload---02" transform="translate(-73.000000, -451.000000)" stroke="#D93708" stroke-width="2">
                                          <g id="Group-Copy" transform="translate(37.000000, 300.000000)">
                                              <g id="Group-4-Copy" transform="translate(43.000000, 152.500000) rotate(-90.000000) translate(-43.000000, -152.500000) translate(42.000000, 147.000000)">
                                                  <line x1="1" y1="0" x2="1" y2="11" id="Line-Copy-2"></line>
                                              </g>
                                          </g>
                                      </g>
                                  </g>
                              </svg>
                              <span>Remove</span>
                              </>
                          </button>
                      </div>
                      <div className="map-right">
                          <img src={mapImage} alt="map" />
                      </div>
                  </div>
                  )
                })
              }
            </div>
            
        </div>
      </div>
    );
  }
  
  export default GpxList;
  